import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
  const navigate = useNavigate();
  const scrollToSection = (sectionId) => {
    const handleNavigationAndScroll = () => {
      const sectionElement = document.getElementById(sectionId);
      const offset = 128;
      if (sectionElement) {
        const targetScroll = sectionElement.offsetTop - offset;
        sectionElement.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
          top: targetScroll,
          behavior: 'smooth',
        });
      }
    };
  
    if (window.location.pathname !== '/') {
      navigate('/');
      setTimeout(handleNavigationAndScroll, 500); // Adjust the timeout as needed
    } else {
      handleNavigationAndScroll();
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', // Align items to the left
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: 'left', // Ensure text alignment is left
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
                  <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Navigation
          </Typography>
          <Link color="text.secondary" onClick={() => scrollToSection('hero')}>
            Home
          </Link>
          <Link color="text.secondary" onClick={() => scrollToSection('Whatwedo')}>
            What We Do
          </Link>
          <Link color="text.secondary" onClick={() => scrollToSection('AboutUs')}>
            About Us
          </Link>
          <Link color="text.secondary" onClick={() => scrollToSection('contact-us')}>
            Contact Us
          </Link>
        </Box>
        </Box>
        
      </Box>
    </Container>
  );
}
