import * as React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function Hero() {
  const theme = useTheme();
  const backgroundImagePath = 'images.jpg'; // Replace with your image path

  return (
    <Box
      id="hero"
      sx={{
        backgroundImage: `url(${backgroundImagePath})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
        textAlign: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          paddingY: 4,
          borderRadius: 2,
          maxWidth: '100%',
          zIndex: 1,
        }}
      >
        <Typography variant="h1" component="h1" color={'black'}>
          <b>Maximize</b> Your Points, <b>Minimize</b> Your Costs!
        </Typography>
        <Typography variant="h2" component="p" color={'black'}>
          <b>The new way to swap points</b>
        </Typography>
        <Typography variant="body1" color={'black'}>
          Swappoi gives you <b>full control</b> over your loyalty points. Connect with a community of savvy users to <b>buy</b>, <b>sell</b>, and <b>trade</b> points. Join exciting auctions or <b>redeem points</b> directly for exclusive products and travel experiences. Discover a new way to <b>maximize your rewards</b> and take charge of the benefits you’ve earned.
        </Typography>
      </Container>
    </Box>
  );
}
