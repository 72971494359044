// db.js
import Aviation from './db/aviation_option.json';
import Electric from './db/aviation_option.json';
import Hotels from './db/aviation_option.json';
import Clothes from './db/aviation_option.json';
import Banking from './db/aviation_option.json';

export const fetchDepartmentData = (department) => {
  try {
      switch (department) {
        case 'Aviation':
          return Aviation;
        case 'Electric':
          return Electric;
        case 'Hotels':
          return Hotels;
        case 'Clothes':
          return Clothes;
        case 'Banking':
          return Banking;
        default:
          return {};
      }
  } catch (error) {
      console.error('Error fetching property data:', error);
      throw error; // Re-throw the error so it can be handled in the calling function
  }
  };

export const fetchObjectData = async (department, name) => {
  try {
    console.log(fetchDepartmentData(department).name);
    return fetchDepartmentData(department)["properties"].find(prop => prop.name = name) || {};
  } catch (error) {
      console.error('Error fetching property data:', error);
      throw error; // Re-throw the error so it can be handled in the calling function
  }
  };
  
export const fetchCountryData = async (country) => {
    try {
      const response = await fetch('/db.json');
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const data = await response.json();
      if (!data[country]) {
        throw new Error('Country not found');
      }
      return data[country];
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Re-throw the error so it can be handled in the calling function
    }
  };
  
export const fetchPropertyData = async (country, propertyName) => {
try {
    const countryData = await fetchCountryData(country);
    const properties = countryData.Properties || [];
    return properties.find(prop => prop.name === propertyName);
} catch (error) {
    console.error('Error fetching property data:', error);
    throw error; // Re-throw the error so it can be handled in the calling function
}
};
  