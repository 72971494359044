import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Paper } from '@mui/material';
import Cookies from 'js-cookie';

const mockUserData = {
  niv: 'nivDoron!',
  ron: 'ronRoytman!',
  yaniv: 'yanivMor!',
};

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = Cookies.get('isLoggedIn');
    if (isLoggedIn) {
      onLogin(true);
      navigate('/');
    }
  }, [onLogin, navigate]);

  const handleLogin = () => {
    if (mockUserData[username] === password) {
      Cookies.set('isLoggedIn', 'true', { expires: 365 });
      Cookies.set('username', username, { expires: 365 });
      onLogin(true, username);
      navigate('/');
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f5f5f5"
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          width: 350, // Increased width to prevent overflow
          textAlign: 'center',
          borderRadius: 2,
          backgroundColor: '#ffffff',
          overflow: 'hidden', // Ensures content does not overflow
        }}
      >
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && (
          <Typography color="error" variant="body2" mt={1}>
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, py: 1.5 }}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Paper>
    </Box>
  );
};

export default Login;
