import React from 'react';
import { Box, Card, CardContent } from '@mui/material';

const DiagramViewer = ({ diagramUrl }) => {
  return (
    <Box mt={2}>
    <Card>
      <CardContent>
        <iframe
          src={diagramUrl}
          width="100%"
          height="850px"
          style={{ border: 'none' }}
          title="Draw.io Diagram Viewer"
          allowFullScreen
        ></iframe>
      </CardContent>
    </Card>
    </Box>
  );
};

export default DiagramViewer;
