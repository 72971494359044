import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import emailjs from 'emailjs-com';
import SendIcon from '@mui/icons-material/Send';
//import LinkedInIcon from '@mui/icons-material/LinkedIn';
//import IconButton from '@mui/material/IconButton';

export default function ContactUs() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const serviceName = 'service_8tc7x3q';
    const publicKey = 'Q7vJbebD-szSKCOG8';
    const templateKey = 'template_fmu8evl';
    const templateParams = {
      from_name: data.get('company_name'),
      to_name: 'Swappoi',
      message: data.get('message'),
      reply_to: 'nivdoron1234@gmail.com'
    };

    const templateParamsToUser = {
        from_name: 'Swappoi',
        to_name: data.get('company_name'),
        message: 'Thank you for reaching out. We have received your message and appreciate your patience. We will ensure to respond to your query at the earliest convenience. If you have any additional information or specific requests, please feel free to share them.',
        reply_to: data.get('email')
      };

    emailjs.send(serviceName, templateKey, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
        emailjs.send(serviceName, templateKey, templateParamsToUser, publicKey)
        .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
        alert("email send!")
        })
        .catch((error) => {
        alert(error.message)
        console.error('Error sending email:', error);
        });
    })
      .catch((error) => {
        alert(error.message)
        console.error('Error sending email:', error);
      });
  };
  return (
    <Container id="contact-us" component="main" maxWidth="sm" sx={{ mt: 8, mb: 8 }}>
      <Typography component="h1" variant="h3" align="center">
        Contact Us
      </Typography>
      {/* 
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography>
        Follow us on LinkedIn:
      </Typography>
      <IconButton
        color="inherit"
        href="https://www.linkedin.com/company/titan-nadlan/?originalSubdomain=il"
        aria-label="LinkedIn"
        sx={{ ml: 1 }}
      >
        <LinkedInIcon />
      </IconButton>
        </Box>
      */}
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="company_name"
              label="Company Name"
              name="company_name"
              autoComplete="company_name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="message"
              label="Message"
              name="message"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          sx={{ mt: 3, mb: 2 }}
        >
        <SendIcon/>
        </Button>
      </Box>
    </Container>
  );
}
