import * as React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hero from './components/LandingPage/Hero';
import WhatWeDo from './components/LandingPage/WhatWeDo';
import AboutUs from './components/LandingPage/AboutUs';
import ContactUs from './components/LandingPage/Contact.us';

export default function LandingPage() {
  return (
    <>
      <Box sx={{ bgcolor: 'background.default' }}>
        <Hero />
        <WhatWeDo />
        <Divider />
        <AboutUs />
        <Divider />
        <ContactUs />
        <Divider />
      </Box>
    </>
  );
}
