import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InfoIcon from '@mui/icons-material/Info';
import HouseIcon from '@mui/icons-material/House';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchObjectData } from '../db';
import ReactDOMServer from 'react-dom/server';
import Carousel from 'react-material-ui-carousel';

export default function Features() {
  const { country, propertyName } = useParams();
  const [newPropertyName, setNewPropertyName] = useState(propertyName);
  const [propertyData, setPropertyData] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showProjectActivities, setShowProjectActivities] = useState(false);
  const propertyIcon = L.divIcon({
    html: ReactDOMServer.renderToString(<HouseIcon size={24} color="red" />),
    className: '',
    iconSize: [24, 24],
  });

  useEffect(() => {
    const loadPropertyData = async () => {
      try {
        setNewPropertyName(propertyName.replaceAll('_', " "));
        const data = await fetchObjectData(country, newPropertyName);
        console.log(data);
        setPropertyData(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    loadPropertyData();
  }, [country, newPropertyName, propertyName]);

  const items = [
    {
      icon: <InfoIcon />,
      title: "About The Project",
      description: "",
      imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
      imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
    },
    {
      icon: <DirectionsRunIcon />,
      title: 'PROJECT ACTIVITIES',
      description: "",
      imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
      imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
    },
  ];
  
    
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
    setShowDescription(index === 0);
    setShowMap(index === 1); // 1 corresponds to the Location item
    setShowProjectActivities(index === 2);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
            <div>
            <Typography component="h2" variant="h4" color="text.primary">
              {newPropertyName}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
            {propertyData ? propertyData.description : ''}
        </Typography>
          </div>
            <Box sx={{ mt: 4, width:'70%', height:'70%'}}>
          {propertyData && propertyData.images && propertyData.images.length > 0 ? (
            <Carousel>
              {propertyData.images.map((image, index) => (
                <Box key={index} sx={{ textAlign: 'center' }}>
                  <img src={image} alt={`Project view ${index + 1}`} style={{ maxWidth: '100%', height: 'auto' }} />
                </Box>
              ))}
            </Carousel>
          ) : (''
          )}
            </Box>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : '';
                    }
                    return selectedItemIndex === index ? 'primary.light' : '';
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : '';
                    }
                    return selectedItemIndex === index ? 'none' : '';
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: 280,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300';
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700';
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
        item
        xs={12}
        md={6}
        sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
      >
      <Card
        variant="outlined"
        sx={{
          height: 'auto', // Change to 'auto' to resize based on content
          width: '100%',
          display: { xs: 'none', sm: 'flex' },
        }}
      >
        <Box sx={{ p: 3, width: '100%' }}>
          <Typography variant="h3" component="div" sx={{ fontWeight: 'bold' }}>
            {showMap ? 'Location' : showDescription ? 'About The Project' : showProjectActivities ? 'Project Activities' : ''}
          </Typography>
          {showMap && propertyData && propertyData.location ? (
            <>
              <Typography variant="body1" component="div">
                {propertyData.location.address}
              </Typography>
              <Box
                id="map"
                sx={{ height: '80vh', width: '100%', mt: 3 }} // Set a specific height for the map
              />
            </>
          ) : showDescription && propertyData ? (
            <Typography variant="body1" component="div" sx={{ mb: { xs: 2, sm: 4 }, whiteSpace: 'pre-line' }}>
              <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
                Description:
              </Typography>
              <Typography variant="body1" component="span">
                {propertyData.description}
              </Typography>
              <br />
              <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
              annual revenue:
              </Typography>
              <Typography variant="body1" component="span">
                {propertyData.annual_revenue}
              </Typography>
              <br />
              <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
              estimated loyalty net worth:
              </Typography>
              <Typography variant="body1" component="span">
                {propertyData.estimated_loyalty_net_worth}
              </Typography>
            </Typography>
          ) : showProjectActivities && propertyData ? (
            <Typography variant="body1" component="div" sx={{ mb: { xs: 2, sm: 4 }, whiteSpace: 'pre-line' }}>
              {propertyData.estimated_point_value}
            </Typography>
          ) : ''}
        </Box>
      </Card>
      </Grid>
      </Grid>
    </Container>
  );
}
