import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import NotFound from './404NotFound';
import Features from './components/LandingPage/Features';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import getLPTheme from './getLPTheme';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from './components/Footer';
import SwappoiDiagramFutureHirarchi from './components/diagrams/drawIO/SwappoiDiagramFutureHirarchi';
import { Box, CircularProgress } from '@mui/material';
import SwappoiDiagram from './components/diagrams/drawIO/SwappoiDiagram';
import Login from './components/SignIn';
import Cookies from 'js-cookie';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const AppRoutes = () => {
  const [mode, setMode] = useState('light');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState(null);

  // Modify the login callback to set the username
  const handleLogin = (isAuthenticated, loggedInUsername) => {
    setIsAuthenticated(isAuthenticated);
    setUsername(loggedInUsername);
  };
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  useEffect(() => {
    const isLoggedIn = Cookies.get('isLoggedIn');
    const storedUsername = Cookies.get('username'); // Retrieve username if stored
    if (isLoggedIn && storedUsername) {
      setIsAuthenticated(true);
      setUsername(storedUsername); // Set the username from cookie
    }
    setIsLoading(false);
  }, []);


  const handleLogout = () => {
    Cookies.remove('isLoggedIn');
    Cookies.remove('username');
    setIsAuthenticated(false);
    setUsername(null);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Router>
    <HelmetProvider>
      <Helmet>
        <title>{`Swappoi`}</title>
      </Helmet>
      <ThemeProvider theme={LPtheme}>
        <CssBaseline />
        {!isAuthenticated ? (
          <Login onLogin={handleLogin} />
        ) : (
          <>
            <AppAppBar
              mode={mode}
              toggleColorMode={toggleColorMode}
              username={username}
              onLogout={handleLogout}
            />
            <Box sx={{ mt: 12 }}> {/* Adds space below the AppBar */}
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/:country/:propertyName" element={<Features />} />
                <Route path="/diagram" element={<SwappoiDiagram />} />
                <Route path="/workers" element={<SwappoiDiagramFutureHirarchi />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
            <Footer />
          </>
        )}
      </ThemeProvider>
      </HelmetProvider>
    </Router>
  );
};

export default AppRoutes;
