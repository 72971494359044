import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: '40px 0',
        backgroundColor: '#fff',
        fontFamily: 'Arvo, serif',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography variant="h1" sx={{ fontSize: '80px', color: '#000' }}>
            404
          </Typography>
          <Box
            sx={{
              backgroundImage: 'url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif)',
              height: '400px',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: -6,
            }}
          >
          </Box>
          <Box sx={{ mt: 0 }}>
            <Typography variant="h3" sx={{ fontSize: '36px', mt: 3 }}>
              Looks like you're lost
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              The page you are looking for is not available!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#39ac31',
                color: '#fff',
                mt: 3,
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#2e8b27',
                },
              }}
              onClick={() => navigate('/')}
            >
              Go to Home
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFound;
