import React from 'react';
import DiagramViewer from './DiagramViewer';

const SwappoiDiagram = () => {    
    const swappoiURLChunks = [
    "https://viewer.diagrams.net/index.html?tags=%7B%7D&lightbox=1&highlight=0000ff&edit=_blank&layers=1&nav=1&title=Swappoi.xml#R%3Cmxfile%3E%3Cdiagram%20id%3D%22C5RBs43oDa-KdzZeNtuy%22%20name%3D%22Page-1%22%3E",
    "7V1Le6o8EP41XepDEq5LitjS4%2B2ovdhNHyqgCEoPYq1d9Ld%2FQUBFtFIrF%2Fl0Iw4kBPLOm8nMJF4hYfxxY8tvw7qlqOYVJJSPK1S5ghBSNMRfrmThS2gaeJKBrSuebEPQ0T9VX0j40pmuqNPQhY5lmY7%2BFhb2rclE7TshmWzb1jx8mWaZ4bu%2ByQM1Iuj0ZTMqfdQVZ",
    "%2BhJWcis5beqPhgGdwY0550Zy8HF%2FpNMh7JizTdESLxCgm1Zjnc0%2FhBU0317wXt5lBaPZs2gb%2B7%2BTv%2FJ99d%2Fuo2HkldZ9SdFVo9gqxPn6KortmIOBnWkCM9PE8Hqvj3OuyVAeXW%2Fy%2BbMf2FXkDbxXa4V%2Fd19aGfhv0n638zy5NM3U8Zw4N3uMFUMlWt8N",
    "JpNHV1blHA%2FOstmuqf7%2BEi1vQsc9cMpyaY%2BmETPLcUl3VHHU%2B%2FkqzxVTX2ieqc1XGdpuoSWe5LwpO750tDvO1cOypDCp8ayPdAnJcd688Ql%2BOa1MXgCfDTwv5dPOn2TJ7tlOx5%2Fsy2ALjPLuvuWadnLuyFi%2BQkunG8073UJXU%2BuyWPd9N%2FhrWq%2Bq47el",
    "3c2svPIt1pNqRw0DHfw79t7orYJzfqBZu0XL%2BEVSGGo6XDojE18BPA56121NXOpdq%2Bm1TewaPndeZP7%2BmTgXzUfYuy4IreCOWYy%2F1l8OgLI%2F131H62y%2BVzTVVWozLq%2FHdsyVMHrUtwchGQSkPLqTNO93nFrAV5H66a5LbOt2URRFb958uvUMmeOytv9oEWudPWLKVN",
    "%2BPRt37WsqqWmruwbkRSzbaM76uiK59fPao0QSxj%2F48vGwepf4pTnqxxYBHmAPsKI0PBio1lh17AUu59eCAOvV448DJSYg%2BPmaVRHtXzTcZFTKF8o%2Bkw9Wla%2FZCh%2F4hPUT8qJ3kNd%2BKI1Ux1kE75%2F9NWq2UYKHFIp63YuSXb24hZI1OtwCqoLHMf9BLNsZWgNrIpvi",
    "WoqrlG2Hd8dIfMnEWrKlOlECSaAvWFTVzeAtqB%2B687RsAQlZ%2F3fPPVcmCOj%2Fbqm2jrvIZefKsikT3F1eIYaCgcAtVSJwMRRItsuFUVq%2BGenP7d5ohVL3CY%2FAKO5fa2b31RjDGn5BA9X57kJuN%2Bo3QO12XxTUgdBWTdnR38PPsQvpy3vgrpEXGxe8WfrEmUYUYdWQX%2BgGk7",
    "xuJKkCkExDBSBL%2FVwFaJo9RgUECRi9xVe3%2BtVXc6UCbFFVgI2oQGBMddv8g1grL%2B2XpG0PbHN9N44sL%2FVvD%2FJrjQCCASp10BopY5DfPn1OJfTwlaQ1QtEhawTAwPLYQC22xXegFjNXQtYItxduYk0Uum1JuADu5ICTasBgKWk0R6yRIOBoMn%2BAC%2FwSlyH%2BuyEekMHv5",
    "K1cpw4MYTCnWs8ilachHu4B99kP8UFTdnDubbMuXvj25Hz7hCFO9AntD3xqJcm3TP7oFu7FWkVsXaB2cqhNG8C4my04%2BktiE4Qah6j8YQ1dhvbDQzvFBEN58rP3PgYjqT2SiO0852poh0Ud2smICrT4drchtju3UutCtfGoViVYLUYQYd4CRqMkin%2FuXrsJUi3FhGdRdEC92cUQ4K4AaGYxB",
    "E1jCUXbC5L8xRBYdIQDlTqGgF2I1t4%2FGl8Tk0mBgAP6OUzAdFEJOBpd89ymF%2B6Nx71byvwd91KjN6neMf8lyL0AwrCdy0EuczM3iyDV1pB4BmZuigxbXjRaUqO6NgLywLBFDVDBaIAqiBRcOPbkHGvzysh65vsJcux2lCAPFBuNSl3zjT%2BdC8BODrA5EtG%2FcrOaIMC2s7A4MpqElTLAUBZRqLMbwxFCKY7i7X7ztkT%2Be8%2FVKF7UTCsUjUFdFCCiADTNpKQAj3%2BBsejd2qNyU86TAqCiempRNDAm1JrdW6lxk7yVEfjXdhkK2yaGv%2Figslp5sLY5uLOzOJ4wzLugJT9ycytBi2M7OpYDkxZlERw7O8IFdFp%2BAxIjsVE3amOhQuaKcMmiEm40NOZmu1zINjGypTHEB3%2BrN3%2Fll2GSZJs7qk0hOpZkECwbB20w1fuGaBE6KsGQeQJGXdPmd7dvWhpUy8Wk2sKuIUApLDE7fwXw02V%2FpgKQPCoRR%2B0B40t67imD5zQSceKrQGH9G1kE6c5OBXw0%2F0wFjjK3TawAhlBiWy0K5UkBCptkjqJhuosCnMIIOgr%2BXQz%2FNlDa3GAwSAP%2B7P%2Be%2F6MRxAv8M5wDlLEC8IYzqZaUNPg%2F8O7%2BfzORySwinP8HBTh2BvAMjCE%2F1R%2F1104aChA7wllUA4jcscpOrNXE9ndqcEkiWd%2BVVl5BjCx8GQBj0IHDm1u0uEowE5QNtnD7bsVTynn4ZDSIeHKSjZ%2BHv9Vh%2Bc%2FDhyS3xb9JRXtckPION7mbdK9TYN%2BAew6yL0kV",
    "lX2zCHeeUAHSckEescz%2FaAX4qwmGSL9%2B5UoBihruJKPhTv5e6ErNBha6hgj%2BkrriZfl1TGtEVTQqpjUiPPCS9F5NcmcVuNpfNbBGKCLzuCcZjXu22s2uKHRFfEy0%2BE7nsdl2Dyt8190ns9rExjBx33FtYoIXBLHT4a%2BlmtTtXVB5clTqYyS0%2B0IzSRuZJkH%2BUBkNRl7oL0mgjW3Oqo5uYJJA41AOgZZFyO%2FsTM608utcJAJlUdHmpTQy%2BuMbnEVd%2BUxGA37X972Luyt2Ul2%2FrwLyIMPWMa7ZOVzwDzefSTIsyZIhhkU0ihBs2t6uTGJq4W7JP8Gmt2jKhaKsvne4Zj%2BNoDIZN6hMFTWmRmUSUzs3BUgvh99VgLtRhfnoPaexaCq%2BAqCiKkAWqwa3Nt7LvwKER4AlmH%2BqASutOZBVRwOjPPliabbkpKEAcY1skJewht%2BElivYnL6yKDx9BX7KdHVfCZaFvyyB%2FJyUvQUQx31XAB94z3lajY5GMNduajx%2FuLhpfrgyR0UkPDiJWGC9JSada0X4mic5iUDc4a3rUvbSUNHA4AVmCcIMdtSHOnMDkoQZIPIHs2j4LXlL5QAY8mKprAFyhwHyMes0NYmXfms%2F5GC03zu2IkSGF4Sz3BbwPNvHL%2FarmjzjKFLTyQbsLBY3qixAG2fyCuyNHT6pIzKL%2FN0%2BQrPQGEY4wwBDnsNZb5DK3jWxN1km86KWe%2BxdaiuGVEL0AZOaC%2FLtji6xGqr2lcBPz3xbIiEzPJMVm%2BfmWTrRdjwxdLrCAePu%2BlPnKAhT0OnYnqXcTKxPPqxFg7f%2B%2F1RgYa3Z42tuUgrRajdv2vxlnhBznhDz31jfX4AxMhG1qPXGSc4TODLsdsnBTupUFsskCYKlmPMxp1yi3cxTXScR7KXdJc%2BCTebdIOJvNuXBQLzplW1HZ9dATI52A9I57NAv6jJJKhrSFfl248Kv8fhVYTRZO%2FyfQC6sF%2BUp%2BTTiZwnyK0RM2KLmAr9MdvxKZxEyPTN%2BPVFSVgyzdlQGxnD%2BOb7Tb7k88Sudl6nqqfmVjgZM22JFTMPTXbxtz2KyrYFB%2Fglfvl44LtHgyrY1mwe2jUbzkmfbs3MjHPH3P0fasw0ZGgOZeW59KPd5ciN4xFxEvs1i2eHZmRtHrbulOXIT%2FzEzVORXaEhfLzO96j7t2UeY9jmNSbq8tTyd8fN39zqaCUidogzmfGJLkzb807uayuD%2BD3vFGRKVEaC44LM1PMWNjdEs7YKCCD7bDSVAmQ6ymlOKltHRMPAOZyIhNOstvpHCqrfiGWVaH9tBh1MR7mfQgGRZvyP%2FrP9kPIEpMEWEw7MlECTtbnAFHcRwQ1zBEklZZZnsRxvul%2FwPSkcFd44M2V6%2FQ2Mxmf51nMraIZOcXUbH3YynuHZZNvHNM1OBEy0diaEA4zk09J54rWiNYZ4UgC6sAuyIb9b55TJo916QaDQbYijU6dkkkpjCn54V0CrRFEWmD1olFlaD%2Bui180w5STrm6S1TGDLRtXxkkFy%2FCWtIJuYpymZ72FCv5J%2BQj%2FDLH2mRoA9oDEiCeHp4SmMpEx3XMw%2BLuj8PHY18Xks3IQYWG12x3WpLnQsHJ8jBpU9ovN6B2QTBRYIcvAJkfjiYyWY56ZkZxUdwsG9H%2F5SDLR4aZJmoi2WmnAYHx96jvqgcHKQrXBZpnEIB1kAWatCQe7OZdc%2FSKQA5d0mp0TxwGoRXKAVrK3%2Fvh8Y%2FbctyNi%2FHQBzWLUV1r%2FgP%3C%2Fdiagram%3E%3C%2Fmxfile%3E#%7B%22pageId%22%3A%22C5RBs43oDa-KdzZeNtuy%22%7D"
    ];
    const swappoiURL = swappoiURLChunks.join("");
  return (
    <DiagramViewer diagramUrl={swappoiURL} />
  );
};

export default SwappoiDiagram;
