import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Avatar from '@mui/material/Avatar';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IconButton from '@mui/material/IconButton';

const tiers = [
  {
    title: 'Mr. Niv Doron',
    position: 'Founder',
    description: [
      'MAS-E student in Engineering from University of California,Berkeley',
      'BSC. in Computer Science from Reichman University (IDC)',
      'Software Engineer',
    ],
    linkedInLink: 'https://www.linkedin.com/in/niv-doron-7188aa206/',
    avatar: 'niv.jpg',
  },
  {
    title: 'Mr. Yaniv Mor',
    position: 'Founding Member',
    description: [
      'BSC. student in Industriel Engineering from Ben Gurion University , Beer Sheva',
    ],
    linkedInLink: 'https://www.linkedin.com/in/yanivmor111/',
    avatar: 'yaniv.jpg',
  },
  {
    title: 'Mr. Ron Roytman',
    position: 'Founding Member',
    description: [
      'BSC. student in data science from Afeka Academic College of Engineering , Tel Aviv',
    ],
    linkedInLink: 'https://www.linkedin.com/in/ron-roytman-39a095218/',

    avatar: 'ron.jpg',
  },
];

export default function AboutUs() {
  return (
    <Container
      id="AboutUs"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          About Us
        </Typography>
        <Typography variant="body1" color="text.secondary">
          
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Mr. Niv Doron1' ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                borderColor:
                  tier.title === 'Mr. Niv Doron1' ? 'primary.main' : undefined,
                background:
                  tier.title === 'Mr. Niv Doron1'
                    ? 'linear-gradient(#60686f, #000000)'
                    : undefined,
              }}
            >
              <CardContent>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  src={tier.avatar}
                  alt={tier.title}
                  sx={{
                    width: 200,
                    height: 200,
                    position: 'relative',
                    top: 0,
                  }}
                />
                <Typography
                  component="h3"
                  variant="h6"
                  sx={{
                    mt: 6,
                    color: tier.title === 'Mr. Niv Doron1' ? 'white' : 'inherit',
                  }}
                >
                  {tier.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                  color: tier.title === 'Mr. Niv Doron1' ? 'grey.50' : undefined,
                }}
              >
                <Typography component="h6" variant="h6">
                  {tier.position}
                </Typography>
              </Box>
              <CardActions
                sx={{ 
                  color: 'lavender',
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center' 
                }}
                  >
                    <IconButton
                      color="inherit"
                      href={tier.linkedInLink}
                      aria-label="LinkedIn"
                      variant={tier.buttonVariant}
                    >
                      <LinkedInIcon />
                    </IconButton>
                  </CardActions>
                  <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === 'Mr. Niv Doron1'
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component="h6"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === 'Mr. Niv Doron1' ? 'grey.200' : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
