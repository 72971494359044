// iconMapping.js
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';

const iconMapping = {
  SettingsSuggestRoundedIcon: <SettingsSuggestRoundedIcon />,
  ThumbUpAltRoundedIcon: <ThumbUpAltRoundedIcon />,
  ConstructionRoundedIcon: <ConstructionRoundedIcon />,
  AutoFixHighRoundedIcon: <AutoFixHighRoundedIcon />,
  SupportAgentRoundedIcon: <SupportAgentRoundedIcon />,
  QueryStatsRoundedIcon: <QueryStatsRoundedIcon />,
  LockIcon: <LockIcon />,
  PeopleIcon: <PeopleIcon />,

};

export default iconMapping;
