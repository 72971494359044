// src/App.js
import React from 'react';
import AppRoutes from './routes';

function App() {
  return (
    <div>
      <AppRoutes />
    </div>
  );
}

export default App;
